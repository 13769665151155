import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <div className='w-1/3 h-full text-white bg-black'>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          1. Acceptance of Terms
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>1.1 Overview</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          By accessing or using our services, you acknowledge that you have read,
          understood, and agree to be bound by these Terms of Service. This agreement
          encompasses any future modifications and additional terms applicable to the
          services, updates, enhancements, or any new features.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>1.2 Binding Agreement</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          These Terms of Service form a legally binding contract between you and
          Raidar. Your use of the services is conditional upon your acceptance of
          these terms.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          1.3 Modifications and Updates
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We reserve the right to modify or revise these Terms of Service at any time.
          Your continued use of our services following any changes signifies your
          agreement to the updated terms. It is your responsibility to regularly check
          these terms for any changes.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>1.4 Eligibility</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          You must be of legal age and capacity to form a binding contract in your
          jurisdiction to agree to these Terms of Service. If you are agreeing to
          these Terms of Service on behalf of an organization or entity, you represent
          and warrant that you have the authority to bind that organization or entity
          to these terms.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>1.5 Prohibited Use</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          If you do not agree with all of these Terms of Service, then you are
          expressly prohibited from using the services and must discontinue use
          immediately. Unauthorized or non-compliant use of the services may result in
          termination of your access and potential legal action.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          1.6 Account Responsibilities
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          In cases where an account creation is necessary for using our services, you
          are responsible for maintaining the confidentiality of your account
          information, including your password, and for all activities that occur
          under your account.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          1.7 Acceptance Through Use
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Your use of the services, including accessing any part of our platform or
          engaging with our content, constitutes your acceptance of these terms. If
          you do not agree to these terms, you should not use our services.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          1.8 Communication of Changes
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Any changes to these Terms of Service will be communicated through our
          platform or via email. We encourage you to review the Terms of Service
          periodically to be informed of any changes.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          1.9 Termination of Agreement
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          You may terminate this agreement at any time by ceasing all use of the
          services and, where applicable, deleting your account. RAIDAR also reserves
          the right to terminate or suspend your access to the services for any breach
          of these Terms of Service.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          1.10 Feedback and Suggestions
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Any feedback, comments, or suggestions you may provide regarding RAIDAR or
          the services is entirely voluntary and we will be free to use such feedback,
          comments, or suggestions as we see fit and without any obligation to you.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          2. Service Description
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          2.1 Overview of RAIDAR Services
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR offers a comprehensive suite of video analytics services that
          leverage cutting-edge machine learning technology. Our services are designed
          to provide advanced video surveillance, analysis, and insights to enhance
          security, efficiency, and decision-making.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>2.2 Scope of Services</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Our services encompass a wide range of capabilities, including but not
          limited to:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Video Surveillance: RAIDAR's video surveillance technology allows for
          real-time monitoring and recording of video footage, enabling you to keep a
          watchful eye on your premises, assets, and operations.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Data Processing: We employ state-of-the-art data processing techniques to
          extract valuable information from video streams, making it easier to
          identify patterns, trends, and anomalies.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Pattern Recognition: Our machine learning algorithms excel in pattern
          recognition, facilitating the automatic detection of events, objects, and
          behaviors within video data.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Behavior Analysis: RAIDAR's behavioral analysis capabilities enable you to
          gain deeper insights into the actions and movements captured by your
          surveillance cameras, helping you make informed decisions.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          2.3 Evolution of Services
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          The technology landscape is constantly evolving, and RAIDAR is committed to
          staying at the forefront of innovation. We regularly update and enhance our
          services to provide you with the latest advancements in video analytics. Any
          significant changes or updates to our services will be communicated to users
          through appropriate channels.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          2.4 Customization and Integration
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We understand that each user's needs may vary. RAIDAR offers customization
          options to tailor our services to your specific requirements. Additionally,
          our services can be seamlessly integrated with your existing infrastructure
          and systems, ensuring a smooth and efficient deployment.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>2.5 Technical Support</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          To ensure a smooth experience, RAIDAR provides technical support to assist
          you with any issues or questions related to our services. Our dedicated
          support team is available to address your inquiries and provide guidance
          when needed.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          2.6 Compliance and Ethical Use
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR emphasizes the lawful and ethical use of our services. Users are
          expected to comply with all applicable laws, regulations, and ethical
          standards when utilizing our video analytics technology. Misuse or illegal
          activities are strictly prohibited.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          2.7 Privacy and Data Protection
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We prioritize the privacy and security of your data. RAIDAR is committed to
          complying with South African data protection laws, including the Protection
          of Personal Information Act (POPIA), and other relevant data protection
          regulations. Your data will not be shared without your explicit consent,
          except as required by law.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>2.8 Authorized Access</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Access to RAIDAR's services is granted exclusively to authorized users who
          have agreed to these Terms of Service. Unauthorized access, sharing of
          credentials, or use of the services by individuals not covered by this
          agreement is strictly prohibited.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>3. User Obligations</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>3.1 Responsible Use</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          As a user of RAIDAR's video analytics services, you are bound by a set of
          responsibilities aimed at ensuring the lawful, ethical, and secure use of
          our technology. We expect all users to adhere to the following obligations:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>3.2 Legal Compliance</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          You agree to use RAIDAR services in full compliance with all applicable
          local, national, and international laws, regulations, and ordinances. This
          includes, but is not limited to, laws related to privacy, data protection,
          surveillance, and intellectual property.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>3.3 Ethical Conduct</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We expect all users to engage in ethical and responsible conduct while using
          our services. This encompasses refraining from any activities that may cause
          harm, discomfort, or distress to others or that violate accepted ethical
          standards.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          3.4 Privacy and Data Protection
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          You are responsible for safeguarding the privacy and data protection rights
          of individuals captured by surveillance cameras or affected by the use of
          our services. This includes obtaining any necessary consents and permissions
          for data collection and ensuring that personal information is handled in
          accordance with applicable data protection laws.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          3.5 Intellectual Property Rights
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR's services, including content, technology, algorithms, methodologies,
          and processes, are the intellectual property of our company. Users are
          strictly prohibited from engaging in unauthorized use, reproduction,
          distribution, reverse engineering, or any other activities that infringe
          upon our intellectual property rights.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          3.6 Prohibition of Misuse
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Any misuse or illegal activities related to our services are strictly
          prohibited. This includes but is not limited to using the services for
          illegal surveillance, harassment, or activities that violate the privacy or
          rights of individuals.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          3.7 Respect for Third-Party Rights
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users must not infringe on the rights of third parties, including but not
          limited to intellectual property rights, privacy rights, or publicity
          rights. Any content or data used with our services must be either owned by
          the user, properly licensed, or used with the necessary permissions.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          3.8 Compliance with Codes of Conduct
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          In addition to legal requirements, users are expected to adhere to any
          industry-specific codes of conduct or standards that apply to the use of
          video surveillance and analytics technologies.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          3.9 Reporting Violations
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          If you become aware of any violations of these user obligations or any
          misuse of our services by others, we encourage you to report such incidents
          to us promptly. We take such reports seriously and will take appropriate
          actions to address violations.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>3.10 Accountability</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users are accountable for their actions and conduct while using RAIDAR's
          services. Any breaches of these user obligations may result in the
          suspension or termination of access to our services and may also have legal
          consequences.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          3.11 Continuous Awareness
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Given the evolving nature of technology and regulations, users are expected
          to stay informed about changes in laws, regulations, and ethical standards
          that may impact their use of RAIDAR services.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          4. Data Privacy and Protection
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          4.1 Our Commitment to Data Privacy
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          At RAIDAR, we recognize the critical importance of data privacy and
          protection. We are dedicated to ensuring that your data is handled with the
          utmost care and in compliance with South African data protection laws,
          including the Protection of Personal Information Act (POPIA), and any other
          relevant data protection regulations. This section outlines our commitment
          to safeguarding your data:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          4.2 Data Collection and Usage
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR's services may involve the collection, processing, and analysis of
          data, including video footage and related information. We collect and use
          data for the sole purpose of providing our video analytics services to you.
          Your data is never used for purposes unrelated to the services without your
          explicit consent.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          4.3 Consent-Based Data Sharing
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Your data is treated with the highest level of respect for your privacy.
          RAIDAR does not share your data with third parties without your explicit
          consent, except as required by law or in cases where data sharing is
          necessary for the provision of our services.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          4.4 Data Security Measures
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We have implemented robust security measures to protect your data from
          unauthorized access, disclosure, alteration, or destruction. These measures
          include encryption, access controls, and regular security assessments to
          ensure the confidentiality and integrity of your data.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>4.5 Data Retention</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We retain your data only for as long as necessary to fulfill the purposes
          for which it was collected. You have the right to request the deletion of
          your data when it is no longer needed, subject to applicable legal
          requirements.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>4.6 User Data Control</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          As a user, you have control over the data you provide to RAIDAR. You can
          access, review, update, and delete your data as needed through our
          platform's user interface, where applicable.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>4.7 Transparency</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We are committed to transparency regarding how your data is used and
          processed. We provide information about data collection, processing
          activities, and data protection practices in our privacy policies and terms
          of service.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>4.8 Data Ownership</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          You retain ownership of the data you provide or generate while using
          RAIDAR's services. We do not claim ownership of your data, and you have the
          right to request its removal from our systems.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>4.9 Data Transfer</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          In the event that data transfer is required, we ensure that it is done
          securely and in compliance with applicable data protection regulations.
          Cross-border data transfers, if necessary, are conducted with appropriate
          safeguards in place.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          4.10 Incident Response
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          In the unlikely event of a data breach or security incident, RAIDAR has
          incident response procedures in place to promptly assess and address the
          situation. Affected users will be notified in accordance with legal
          requirements.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          4.11 Continuous Compliance
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We are committed to maintaining compliance with evolving data protection
          laws and regulations. Our data protection practices are regularly reviewed
          and updated to align with the latest legal requirements.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>4.12 User Education</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We encourage users to educate themselves about data privacy and protection
          best practices. This includes understanding their rights, responsibilities,
          and the implications of data sharing.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>4.13 Contact Us</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          If you have any questions or concerns regarding data privacy and protection,
          or if you wish to exercise your rights under applicable data protection
          laws, please contact our Data Protection Officer using the provided contact
          information in our privacy policies.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          5. Service Modifications
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>5.1 Dynamic Services</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR's commitment to providing cutting-edge video analytics services means
          that our offerings are dynamic and responsive to the evolving needs of our
          users and advancements in technology. In this section, we elaborate on the
          nature of service modifications and what you can expect:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>5.2 Service Evolution</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR's video analytics platform is designed to evolve continuously. We
          understand that technology is constantly advancing, and our users benefit
          from our commitment to staying at the forefront of innovation. This
          commitment drives our efforts to enhance and expand our services regularly.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>5.3 Scope of Services</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Our services encompass a broad range of functionalities, including but not
          limited to video surveillance, data processing, pattern recognition, and
          behavior analysis. These capabilities are designed to address various use
          cases, from security and safety to business intelligence and process
          optimization.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>5.4 Regular Updates</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          To keep our users informed and engaged, we communicate any significant
          changes, updates, or enhancements to our services. Whether it's the
          introduction of new features, improved algorithms, or expanded customization
          options, you can expect transparency regarding how our services evolve.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          5.5 Enhanced Capabilities
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          As technology advances, so do our service capabilities. RAIDAR is committed
          to providing state-of-the-art video analytics tools that enable you to
          derive greater insights from video data. We invest in research and
          development to ensure that our services remain at the cutting edge of
          innovation.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          5.6 Customization Options
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We understand that the needs of our users may vary, and one size does not
          fit all. RAIDAR offers customization options that allow you to tailor our
          services to your specific requirements. Whether it's adjusting algorithms,
          configuring alerts, or integrating with other systems, we aim to provide
          flexibility.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          5.7 Compatibility and Integration
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR's services are designed to seamlessly integrate with your existing
          infrastructure and systems. We work to ensure compatibility with a wide
          range of devices, cameras, and platforms, making it easy for you to leverage
          our technology within your organization.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>5.8 Ethical Use</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          While we continuously enhance our services, we emphasize the importance of
          their ethical and responsible use. Our commitment to ethical use extends to
          the development of features and functionalities that promote privacy,
          security, and compliance with legal and ethical standards.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          5.9 Feedback-Driven Improvements
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          User feedback is invaluable to us. We actively seek input from our users to
          understand their needs and challenges better. Your feedback plays a
          significant role in shaping the direction of service modifications and
          improvements.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          5.10 User-Centric Approach
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR places users at the center of our service development process. We aim
          to provide solutions that not only meet your current needs but also
          anticipate future requirements, ensuring that our services remain relevant
          and valuable.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>5.11 Staying Informed</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          To stay informed about service modifications and updates, users are
          encouraged to regularly check notifications within our platform, visit our
          website, and subscribe to our newsletters and communications channels.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          5.12 Commitment to Quality
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Our commitment to service modifications is matched by our commitment to
          maintaining high-quality standards. We conduct rigorous testing and quality
          assurance processes to ensure that updates and enhancements are reliable and
          perform as intended.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>5.13 User Support</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Should you have any questions or require assistance regarding service
          modifications, our dedicated user support team is available to provide
          guidance and address your inquiries.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          6. Intellectual Property
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          6.1 Our Intellectual Property Assets
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR takes pride in the innovative content and technology that power our
          video analytics services. In this section, we elaborate on the intellectual
          property aspects, including content ownership, proprietary methodologies,
          and your rights as a user:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          6.2 Ownership and Protection
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          All content, technology, algorithms, methodologies, and processes provided
          by RAIDAR are the intellectual property of our company. This intellectual
          property is protected by various intellectual property laws and
          international treaties.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>6.3 Restricted Usage</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Unauthorized use, reproduction, distribution, reverse engineering, or any
          other activities that infringe upon our intellectual property rights are
          strictly prohibited. Users are granted a limited, non-exclusive, and
          non-transferable right to access and use our services solely for their
          intended purpose.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>6.4 Use of Algorithms</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          The algorithms developed and deployed within RAIDAR's services are
          proprietary and form the foundation of our machine learning technology.
          Users are not granted any rights over these algorithms other than for the
          purpose of using our services as intended.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          6.5 Respect for Copyright
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We respect copyright and intellectual property rights, and we expect our
          users to do the same. Users must not upload, share, or use any content that
          they do not have the legal right to use, including copyrighted material.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          6.6 User-Generated Content
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Any content or data generated or uploaded by users while using our services
          remains the property of the respective user. RAIDAR does not claim ownership
          of user-generated content, and users retain their rights over such content.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>6.7 Data Usage Rights</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR respects your data rights. While we may process and analyze your data
          to provide our services, we do not claim ownership of the data itself. You
          retain full ownership and control of the data you provide or generate
          through our platform.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          6.8 User Feedback and Suggestions
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We welcome user feedback, comments, or suggestions regarding our services.
          However, any feedback provided by users is entirely voluntary, and users do
          not gain intellectual property rights over their feedback. RAIDAR is free to
          use such feedback as it sees fit without any obligation to the user.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          6.9 Reporting Intellectual Property Violations
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          If you believe that any content or activity within our platform infringes
          upon your intellectual property rights or violates copyright laws, please
          report such violations to us. We take intellectual property rights seriously
          and will take appropriate actions in response to valid reports.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          6.10 Ethical and Legal Use
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users are expected to use RAIDAR's services in an ethical and legally
          compliant manner. This includes respecting intellectual property rights,
          refraining from unauthorized use of copyrighted materials, and ensuring that
          any content used with our services is either owned by the user or used with
          the necessary permissions.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          6.11 Compliance with Terms
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          By using RAIDAR's services, users agree to abide by these intellectual
          property terms and all other terms and conditions outlined in this
          agreement. Failure to comply with these terms may result in the termination
          of access to our services.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          7. Liability Limitation
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          7.1 Understanding Our Liability
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR places a strong emphasis on clarity regarding our liability for the
          use of our video analytics services. In this section, we provide an in-depth
          explanation of our liability limitations and the scope of our
          responsibilities:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          7.2 Exclusion of Certain Damages
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR explicitly disclaims any liability for indirect, incidental, or
          consequential damages arising from the use of our services. This includes,
          but is not limited to, damages such as loss of data, loss of profits,
          business interruption, or any other financial or non-financial losses.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>7.3 Primary Purpose</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Our primary purpose is to provide advanced video analytics services that
          enhance security, efficiency, and decision-making for our users. While we
          take every measure to ensure the reliability and quality of our services, we
          acknowledge that no technology is entirely free from potential issues or
          disruptions.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          7.4 Service Reliability
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR strives to maintain the reliability and availability of our services.
          However, we cannot guarantee uninterrupted or error-free access to our
          platform at all times. Users acknowledge that factors beyond our control,
          such as internet connectivity and device performance, may impact service
          availability.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          7.5 No Warranty of Perfection
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          While we invest in research and development to provide state-of-the-art
          technology, we do not warrant that our services are entirely error-free or
          flawless. Users accept that the use of machine learning and video analytics
          inherently involves some level of uncertainty, and results may vary.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>7.6 Legal Limitations</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR's liability is limited to the extent permitted by applicable law. In
          no event shall our liability exceed the amount paid by the user for the
          specific services provided during the preceding twelve (12) months.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          7.7 No Assumption of Liability
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          By using RAIDAR's services, users explicitly acknowledge that they do so at
          their own risk. Users assume full responsibility for any consequences,
          including any potential damages or losses, that may result from their use of
          the services.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          7.8 No Guarantee of Outcomes
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR does not guarantee specific outcomes, results, or achievements from
          the use of our services. While our technology is designed to provide
          valuable insights and enhance security, the effectiveness of our services
          may vary based on individual circumstances and user-specific factors.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          7.9 User Responsibilities
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users are responsible for configuring and using RAIDAR's services in a
          manner that aligns with their specific needs and requirements. This includes
          setting up alerts, monitoring, and interpreting data generated by our
          platform.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          7.10 Mitigation of Damages
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users are encouraged to take reasonable precautions to mitigate potential
          damages or losses when using our services. RAIDAR's liability limitations
          should not discourage users from implementing their own risk management
          practices.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          7.11 Termination of Liability
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR's liability provisions continue to apply even in the event of
          termination of this agreement or the user's access to our services. Users
          are advised to review and understand these provisions carefully.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>8. Indemnification</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          8.1 Understanding Indemnification
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Indemnification is an important aspect of our terms and conditions that
          outlines the responsibilities of users in certain situations. In this
          section, we provide a detailed explanation of indemnification and what it
          means for our users:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          8.2 User's Legal Obligation
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users agree to indemnify and hold RAIDAR harmless from any claims,
          liabilities, damages, losses, costs, or expenses, including legal fees and
          expenses, arising from or related to their use of our video analytics
          services.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          8.3 Scope of Indemnification
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          The scope of indemnification includes but is not limited to:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Claims from third parties: Users are responsible for any claims brought
          against RAIDAR by third parties arising from the user's use of our services.
          This may include claims related to privacy violations, intellectual property
          infringements, or any other legal disputes.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Violation of Terms: Users are responsible for indemnifying RAIDAR in case of
          a breach of these Terms and Conditions or any other agreements or policies
          referenced herein.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Misuse or Misconduct: Users are liable for any damages or losses incurred by
          RAIDAR or third parties due to the user's misuse or misconduct while using
          our services.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>8.4 Legal Defense</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR reserves the right to assume the exclusive defense and control of any
          matter subject to indemnification by the user. Users agree to cooperate with
          RAIDAR in defending against any claims and to reimburse RAIDAR for any costs
          incurred in the defense of such claims.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          8.5 Notification of Claims
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users are required to promptly notify RAIDAR of any claims, demands, or
          actions brought against them that may give rise to indemnification
          obligations. Failure to provide timely notice may impact the user's ability
          to claim indemnification.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          8.6 Reasonable Measures
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users are encouraged to take reasonable measures to prevent or mitigate
          potential claims, disputes, or legal actions related to their use of
          RAIDAR's services. This includes ensuring compliance with these Terms and
          Conditions and applicable laws.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          8.7 No Exemption from Liability
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Indemnification does not exempt users from their own liability for damages
          or losses resulting from their actions or use of our services. It is a
          mechanism to allocate responsibility in certain situations where RAIDAR may
          be held accountable.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          8.8 Continuous Compliance
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users are expected to remain in compliance with these Terms and Conditions
          and to fulfill their indemnification obligations even after the termination
          of this agreement or the user's access to our services.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          8.9 Mutual Cooperation
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR values transparency and cooperation with our users. In the event of a
          claim or dispute, we encourage open communication and collaboration to
          resolve issues efficiently and amicably.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          9. Termination of Services
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          9.1 Understanding Termination
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          The termination of services is an important aspect of our terms and
          conditions that defines the circumstances under which RAIDAR may end the
          provision of our video analytics services to a user. In this section, we
          provide a detailed explanation of the termination process and its
          implications:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          9.2 RAIDAR's Right to Terminate
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR reserves the right to terminate services for any user who violates
          these Terms and Conditions or engages in conduct that we deem detrimental to
          the integrity, security, or reputation of our services.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          9.3 Notice of Termination
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          In cases where we decide to terminate services, we will provide notice to
          the user through the contact information provided during registration or
          through the user's account on our platform. We will make reasonable efforts
          to provide advance notice, but in certain circumstances, termination may be
          immediate.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          9.4 Reasons for Termination
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Termination of services may occur for various reasons, including but not
          limited to:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Breach of Terms: Users who breach any provision of these Terms and
          Conditions, including user obligations, intellectual property, or ethical
          use, may face termination.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Illegal Activities: Engaging in illegal activities while using our services
          will result in immediate termination.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Violation of Third-Party Rights: Users who infringe upon the rights of third
          parties, including intellectual property rights, privacy rights, or
          publicity rights, may be subject to termination.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Security Concerns: RAIDAR may terminate services if there are security
          concerns related to a user's activities or account, including attempts to
          compromise our systems or misuse our technology.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Non-Payment: Failure to make timely payments for services, as outlined in
          the Payment section of these Terms and Conditions, may result in suspension
          or termination.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          9.5 Effect of Termination
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Upon the termination of services, users lose access to our platform, and
          their account will be deactivated. Any data, configurations, or content
          associated with the user's account may be permanently deleted, subject to
          applicable data retention policies.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          9.6 Obligations After Termination
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users remain bound by the provisions of these Terms and Conditions even
          after the termination of services. This includes, but is not limited to,
          liability limitations, indemnification, intellectual property rights, and
          confidentiality obligations.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>9.7 Appeal Process</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users who believe their termination was unjust or in error may request a
          review or appeal of the decision. RAIDAR will consider such appeals and may
          reinstate services if the user's case is found to have merit.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>9.8 Data Retrieval</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          In cases of termination, users may request the retrieval of their data,
          subject to applicable data protection laws and policies. RAIDAR will make
          reasonable efforts to assist with data retrieval, but users are encouraged
          to maintain their own data backups.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          9.9 Continued Compliance
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users are encouraged to maintain continued compliance with these Terms and
          Conditions to avoid termination. Compliance not only ensures uninterrupted
          access to our services but also fosters a positive and secure user
          experience.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>10. Governing Law</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>10.1 Legal Framework</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          The governing law is a crucial aspect of our terms and conditions that
          specifies the legal framework within which disputes, issues, or conflicts
          related to these Terms and Conditions and our video analytics services will
          be addressed. In this section, we provide an in-depth explanation of the
          governing law and its implications:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          10.2 Applicable Jurisdiction
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          These Terms and Conditions are governed by the laws of South Africa. This
          means that any legal matters, disputes, or claims arising from the use of
          RAIDAR's video analytics services will be subject to the legal jurisdiction
          of South Africa.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>10.3 Choice of Law</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          The choice of South African law as the governing law is based on our
          company's location and headquarters in South Africa. It reflects our
          commitment to operating within the legal framework of our home jurisdiction.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          10.4 Dispute Resolution
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          In the event of disputes or legal actions related to these Terms and
          Conditions, RAIDAR and users agree to submit to the exclusive jurisdiction
          of the South African courts. This choice of jurisdiction provides a clear
          and consistent legal process for resolving disputes.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          10.5 Protection of Rights
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          The choice of governing law and jurisdiction is intended to protect the
          rights and interests of both RAIDAR and our users. It provides a legal
          framework that ensures fair and consistent resolution of any legal matters
          that may arise.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          10.6 Understanding Local Laws
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users are encouraged to familiarize themselves with South African laws and
          regulations that may be relevant to their use of RAIDAR's services. This
          includes compliance with data protection laws, intellectual property rights,
          and any other legal requirements that may apply.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          10.7 International Application
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          While South African law governs these Terms and Conditions, RAIDAR
          recognizes that our services may be used by users from around the world. We
          strive to operate in compliance with applicable international laws and
          regulations as well.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          10.8 Language of Legal Proceedings
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Legal proceedings related to these Terms and Conditions and our services
          will be conducted in the English language, in accordance with South African
          legal practices.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          10.9 Amicable Resolution
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR values amicable resolution of disputes and encourages open
          communication and negotiation to resolve issues whenever possible. Users are
          encouraged to reach out to our support team to address concerns before
          resorting to legal action.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          10.10 Continuous Compliance
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users are expected to maintain continued compliance with these Terms and
          Conditions and to adhere to the governing law. Compliance not only ensures
          legal adherence but also contributes to a positive and secure user
          experience.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          11. Amendments to Terms
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          11.1 Adapting to Changing Needs
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR recognizes the need to adapt to evolving circumstances and user
          requirements. In this section, we provide a detailed explanation of our
          approach to amending these Terms and Conditions and the implications for our
          users:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          11.2 RAIDAR's Right to Amend
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR reserves the right to amend, modify, or update these Terms and
          Conditions at any time, without prior notice. Users are encouraged to
          regularly review these terms to stay informed about any changes.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          11.3 Reasons for Amendments
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Amendments to these Terms and Conditions may occur for various reasons,
          including but not limited to:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Legal Compliance: Changes may be made to ensure compliance with new laws,
          regulations, or legal requirements that impact our services or user rights.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Service Enhancements: Amendments may reflect enhancements, expansions, or
          improvements to our services, including the introduction of new features or
          functionalities.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          User Feedback: We value user feedback and may make changes in response to
          user suggestions, comments, or needs.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Security Measures: Amendments may be introduced to enhance the security and
          integrity of our platform and user data.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          11.4 Notice of Amendments
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          While we may not provide individual notice of amendments, we will make
          efforts to communicate significant changes through notifications within our
          platform, on our website, or via email newsletters. It is the user's
          responsibility to stay informed about updates.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          11.5 Continued Use Constitutes Acceptance
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Continued use of RAIDAR's video analytics services after the introduction of
          amendments constitutes acceptance of the new terms. Users are bound by the
          most recent version of these Terms and Conditions.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          11.6 User Review of Amendments
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users are encouraged to review and understand amendments to these terms
          carefully. If a user disagrees with the amended terms, they have the option
          to discontinue the use of our services.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          11.7 Transparency and Clarity
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR is committed to maintaining transparency and clarity in amendments.
          We strive to provide explanations and context for changes, ensuring that
          users can make informed decisions about their continued use of our services.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>11.8 Appeal Process</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users who believe that amendments are unfair or require special
          consideration may contact our support team to discuss their concerns. While
          we cannot guarantee changes to the terms, we value user input.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          11.9 Consistent Compliance
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users are expected to maintain consistent compliance with the most recent
          version of these Terms and Conditions. This helps ensure a harmonious and
          secure user experience while using our services.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          11.10 Legal Continuity
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Amended terms do not alter the continuity of legal obligations, rights, or
          responsibilities that users and RAIDAR have established under previous
          versions of these Terms and Conditions. Legal commitments made under earlier
          terms remain in effect unless explicitly superseded.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>12 Installation</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          All aspects of the installation of any component not directly part of the
          RAIDAR software system are to be installed by the client.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>13 Payment.</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Payment for the services provided under this agreement is critical to the
          continuation of our work and support. EPSza requires that all invoices be
          paid within a reasonable time of issuance. Failure to make timely payments
          may result in the suspension or termination of services, including any
          ongoing maintenance or support. It is our policy to work collaboratively
          with clients to avoid such situations, but we reserve the right to cease
          services if financial obligations are not met as agreed.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          14. User Registration and Account Integrity
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          14.1 Importance of Registration
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          User registration and account integrity are fundamental aspects of utilizing
          RAIDAR's video analytics services effectively and securely. In this section,
          we provide a detailed explanation of the user registration process, account
          responsibilities, and the significance of maintaining account integrity:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>14.2 Account Creation</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          To access certain features and functionalities of our service, users may be
          required to complete a registration process. During registration, users are
          requested to provide accurate and up-to-date information, including their
          name, contact details, and other required information.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          14.3 Accurate Information
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users are responsible for ensuring the accuracy of the information provided
          during registration. Providing false or misleading information may result in
          the suspension or termination of the user's account.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          14.4 Protection of Credentials
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users are responsible for safeguarding their account credentials, including
          usernames and passwords. It is crucial to maintain the confidentiality of
          these credentials to prevent unauthorized access to the account.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          14.5 Unauthorized Access
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users are encouraged to notify RAIDAR immediately in case of any
          unauthorized access to their account or suspected security breaches. We take
          security seriously and will take appropriate actions to address such
          incidents.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          14.6 Responsibility for Account Activities
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users are solely responsible for all activities that occur under their
          account, whether authorized or unauthorized. It is important to log out from
          the account after each session, especially when using shared or public
          computers.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          14.7 Account Integrity
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR expects users to maintain the integrity of their accounts by
          refraining from engaging in any activities that may compromise the security,
          privacy, or reputation of their account or the RAIDAR platform.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          14.8 Prohibited Activities
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Prohibited activities that jeopardize account integrity include, but are not
          limited to:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Unauthorized access to other user accounts.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Attempted or actual disruption of service functionality.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Use of automated scripts or tools to access or interact with the RAIDAR
          platform.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Sharing of account credentials with third parties.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          14.9 Consequences of Violations
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Violations of account integrity or engagement in prohibited activities may
          result in account suspension or termination. RAIDAR reserves the right to
          take appropriate actions to protect the integrity of our platform and user
          accounts.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>14.10 Data Privacy</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR is committed to protecting user data privacy and complies with data
          protection laws. User registration information is treated in accordance with
          our Privacy Policy, and users can review our privacy practices for more
          information.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          14.11 User Responsibility
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users play a critical role in maintaining account integrity and data
          security. It is essential to be vigilant, report suspicious activities, and
          follow best practices for online security.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          14.12 Continued Compliance
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users are expected to maintain continued compliance with these account
          integrity guidelines and to follow any additional security measures
          recommended by RAIDAR. This helps ensure a secure and trustworthy user
          environment.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>14.13 User Support</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          If users have any questions, concerns, or require assistance with
          account-related issues, our user support team is available to provide
          guidance and address inquiries related to registration, account management,
          and security.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>15. Confidentiality</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          15.1 Understanding Confidentiality
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Confidentiality is a fundamental aspect of RAIDAR's commitment to protecting
          user data and sensitive information. In this section, we provide a detailed
          explanation of confidentiality, the types of information it encompasses, and
          the responsibilities of users:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          15.2 Confidential Information
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR recognizes that during your use of our video analytics services, you
          may have access to information that is considered confidential. This
          confidential information may include, but is not limited to, proprietary
          technology, business strategies, user data, and other non-public
          information.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          15.3 Strict Confidentiality Obligation
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users acknowledge and agree that any information treated as confidential by
          RAIDAR must be handled with the strictest confidence. Users are prohibited
          from disclosing, sharing, or using such confidential information for any
          purpose outside the scope of these Terms and Conditions.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          15.4 Protection of RAIDAR's Confidential Information
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users are obligated to protect RAIDAR's confidential information from
          unauthorized access or disclosure. This includes implementing reasonable
          security measures to prevent breaches or leaks of confidential data.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          15.5 No Unauthorized Use
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users must not use RAIDAR's confidential information for personal gain,
          competitive advantage, or any purpose that is not expressly permitted by
          these Terms and Conditions. Unauthorized use of confidential information is
          strictly prohibited.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          15.6 Scope of Confidentiality
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          The scope of confidentiality includes, but is not limited to, the following
          categories of information:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Technology and Algorithms: Any proprietary technology, algorithms,
          methodologies, or processes used by RAIDAR in its video analytics services
          are considered confidential.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Business Strategies: RAIDAR's business strategies, including plans for
          future development, marketing, and expansion, are confidential and should
          not be disclosed or used without authorization.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          User Data: Information related to user accounts, data generated by users,
          and any user-specific configurations are considered confidential and
          protected under these terms.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Trade Secrets: Any information classified as a trade secret, including the
          inner workings of RAIDAR's technology and services, is treated as
          confidential.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          15.7 Legal Obligations
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR complies with applicable data protection laws and regulations,
          including those related to the confidentiality of user data. Users can refer
          to our Privacy Policy for details on how user data is handled and protected.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          15.8 User Data Privacy
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR respects and safeguards user data privacy. While we may process and
          analyze user data to provide our services, we do not claim ownership of the
          data itself. Users retain full ownership and control of the data they
          provide or generate through our platform.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          15.9 Handling User-Generated Content
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users retain ownership of any content or data generated or uploaded while
          using our services. RAIDAR does not claim ownership of user-generated
          content, and users retain their rights over such content.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          15.10 Enduring Obligation
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          The obligation to maintain confidentiality continues even after the
          termination of this agreement or the user's access to our services. Users
          must continue to protect any confidential information they have access to.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>15.11 Mutual Respect</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          RAIDAR values the mutual respect of confidentiality obligations between
          users and our company. We expect users to adhere to these obligations, just
          as we commit to safeguarding user data and confidential information.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          15.12 Reporting Violations
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Users are encouraged to report any suspected violations of confidentiality,
          whether involving RAIDAR's confidential information or user data, to our
          support team. We take such reports seriously and will take appropriate
          actions to address violations.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
      </div>

    </div>
  );
}

export default App;
